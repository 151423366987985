<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="children-banner"
    >
      <v-carousel height="100%"
                  class="carousel-new carousel-banner"
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="false">
        <v-carousel-item v-for="(banner,index) in bannerList" :key="index">
          <v-img
              :src="banner.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center justify-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in banner.title" :key="tipIndex" class="pt-2" style="font-weight: 500">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-4 justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  {{banner.tip}}
                </v-card-title>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-banner>
    <div class="d-flex justify-center" style="padding-bottom: 70px;">
      <v-card class="max-width-1200" flat>
        <div class="public-title">
          <p>STATUS QUO</p>
          <div>
            <span>企业获客现状</span>
          </div>
        </div>
        <v-container class="width-per-100">
          <v-row class="justify-center" style="width:1200px;">
            <v-col class="pa-0" cols="4"  v-for="(card,index) in productList" :key="index">
              <v-card elevation="0" style="width:1200px;">
                <div class="d-flex justify-center"><v-img class="flex-grow-0" :src="card.img" width="120" height="120">
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img></div>
                <v-card-title class="justify-center font-size-24" v-text="card.title"></v-card-title>
                <v-card-text class="font-size-14 text-align-center text-no-wrap pb-0" v-text="card.tip" style="line-height:26px;"></v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <!-- 我们的数据能力 -->
    <div class="d-flex justify-center width-per-100" style="padding-bottom: 60px;background-color: #F5F9FD;">
      <v-card flat class="max-width-1200 width-per-100" color="transparent">
        <div class="public-title">
          <p>OUR DATA</p>
          <div>
            <span>我们的数据能力</span>
          </div>
        </div>
        <v-tabs v-model="tab" centered background-color="transparent" class="g-tabs font-size-20">
          <v-tab v-for="(v, k) in ourDataList" :key="k">
            <b>{{ v.tabTitle }}</b>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mt-12" style="background-color: transparent;">
          <v-tab-item v-for="(v, k) in ourDataList" :key="k"
                      :transition="false">
            <div class="d-flex justify-center" style="width:900px;margin:0 auto;">
              <transition name="slide-fade" appear>
                <v-img style="background-color: transparent"
                       width="400" max-width="400" max-height="260" min-height="260"
                       v-show="tab === k"
                       :src="v.img">
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          width="8"
                          color="grey lighten-3"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div style="margin-left: 80px;width: 385px;"
                     class="d-flex align-center"
                     v-show="tab === k">
                  <div>
                    <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">{{v.title}}</v-card-title>
                    <v-card-text class="pa-0 mt-4 font-size-16 color-666">
                      <div class="width-per-100 white-space-pre-wrap">{{v.tip}}</div>
                    </v-card-text>
                  </div>
                </div>
              </transition>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <div class="d-flex justify-center pb-15">
      <v-card class="max-width-1200" flat>
        <div class="public-title">
          <p>DATA APPLICATION</p>
          <div>
            <span>数据应用</span>
          </div>
        </div>
        <v-container>
          <v-row class="lavMaxWidth justify-center" style="width:1200px;">
            <v-col cols="4"  v-for="(card,index) in dataAppList" :key="index">
              <v-card elevation="0">
                <div class="d-flex justify-center"><v-img class="flex-grow-0" :src="card.img" width="120" height="120">
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img></div>
                <v-card-title class="justify-center font-size-24" v-text="card.title"></v-card-title>
                <v-card-text class="font-size-14 text-align-center" v-text="card.tip" style="line-height:26px;"></v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "BigData",
  components: {
  },
  data() {
    return {
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/MD-bigData-banner.png",
          title: ["为广告主提供全方位的数据服务"],
          titleColor:"#FFFFFF",
          tip: "为品牌提供基于真实用户体系、多元丰富内容、精准数据洞察和领先技术算法的数字营销系统",
        }
      ],
      data: {},
      currentIndex: 0,
      productList: [
        {
          title: "获客成本高",
          tip: "各大媒体流量，价格攀升，成本压力激增",
          img: require("../../../public/img/icons/marketingDiversion/bigData01.png"),
        },
        {
          title: "营销效果差",
          tip: "网络流量质量差，转化低，作弊刷量并行",
          img: require("../../../public/img/icons/marketingDiversion/bigData02.png"),
        },
        {
          title: "媒体渠道窄",
          tip: "营销渠道受限，不能完全覆盖潜在用户",
          img: require("../../../public/img/icons/marketingDiversion/bigData03.png"),
        }
      ],
      dataAppList: [
        {
          title: "潜客召回",
          tip: "锁定点击广告无后续行为的用户，进行二次营销",
          img: require("../../../public/img/icons/marketingDiversion/bigData04.png"),
        },
        {
          title: "精准获客",
          tip: "根据用户画像，筛选符合维度的人群，实现精准营销",
          img: require("../../../public/img/icons/marketingDiversion/bigData05.png"),
        },
        {
          title: "会员标签补全",
          tip: "会员信息缺失，数据库进行匹配，完善会员属性",
          img: require("../../../public/img/icons/marketingDiversion/bigData06.png"),
        }
      ],
      tab:null,
      ourDataList: [
        {
          tabTitle: '行为分析',
          img: "https://h5.ophyer.cn/official_website/other/MD-bigData01.png",
          title: '10亿+用户行为分析',
          tip: '目前已经具有超过10亿移动用户（设备）上网行为数据分析能力，形成了一套完善的大数据标签服务体系。',
        },
        {
          tabTitle: '智能标签',
          img: "https://h5.ophyer.cn/official_website/other/MD-bigData02.png",
          title: '近万级标签数量',
          tip: '通过对用户的行为分析，总结出用户的对应属性或需求，最后通过标签的方式展示出来，一个用户可有多种标签。',
        },
        {
          tabTitle: '全案营销',
          img: "https://h5.ophyer.cn/official_website/other/MD-bigData03.png",
          title: '全案营销策略',
          tip: '制定组合策略推广方案，定位投放目标客户人群，相对传统的广告投放提高了100%-200%的投放精准度。',
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>